import React from 'react'
import {AppWrap, MotionWrap} from '../../wrapper'
import './Footer.scss'

function Footer() {

  return (
    <>
      <h2 className='head-text'>Contact me</h2>
      <p className='p-text'>If you'd like to work with me, feel free to say hello or ask me any questions. I'll try to get back to you as soon as I could!</p>
      <div className='app__footer-cards'>
        <button className='contact'>
                  
          <a href='mailto:me@febbygunawan.com' className='p-text'>Get In touch</a>
        </button>
        <button className='resume'>
          
          <a href='https://drive.google.com/file/d/1eep7GeJ0sdfIuGj_7ITwG0O4W9N5Yj8i/view?usp=sharing'target='_blank' rel="noreferrer" className='p-text'>View Resume</a>
        </button>
       
        
      </div>
        
    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__primarybg'
)