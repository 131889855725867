import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { AppWrap,MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import './Skills.scss'

const Skills = () => {
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';
    const skillsQuery = '*[_type == "skills"]';

    client.fetch(query).then((data) => {
      // console.table(data)
      setExperiences(data);
    });

    client.fetch(skillsQuery).then((data) => {
      // console.table(data)
      setSkills(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">Skills & Experiences</h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list"
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5 }}>

          {skills.map((skill) => (

            <div
              
              className="app__skills-item app__flex"
              key={skill._id}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
               
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
              
            </div>
          ))}

        </motion.div>

        <div className="app__skills-exp">
          {experiences.map((experience) => (
            <div
              className="app__skills-exp-item"
              key={experience._id}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              
              <div className="app__skills-exp-works">
                {experience.works.map((work) => (
                  
                    <div className="app__skills-exp-work"
                    id={work.company}
                     key={work.name}     
                    >
                      <h4 className="bold-text">{work.name}</h4>
                      <p className="p-text">{work.company}</p>
                      <p className="p-text">{work.desc}</p>
                      
                    </div>
                    
                  
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__whitebg',
);