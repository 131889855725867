import React, {useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { AppWrap,MotionWrap } from '../../wrapper';

import './About.scss'

import { urlFor,client } from '../../client';

// const abouts = [
//   {title: 'Web Development',description: 'Lorem Ipsum',imgURL: images.about01  },
//   {title: 'Web Design',description: 'Lorem Ipsum',imgURL: images.about02 },
//   {title: 'UI/UX Design',description: 'Lorem Ipsum',imgURL: images.about03 },
//   {title: 'Consultation',description: 'Lorem Ipsum',imgURL: images.about04 },
// ]

function About() {
  const [abouts, setAbouts]= useState([]);
  useEffect(() => {
    const query = '*[_type == "abouts"]'
    client.fetch(query)
      .then((data) => { setAbouts(data)})
  }, [])
  

  return (
    <>
    <h2 className="app__profiles-head-text">About <span>Me</span></h2>
    <p className="app__profiles-p-text">Hi, my name is Febby. My interest in web development started back in 2014 when I decided to try editing custom Wordpress themes — turns out customizing the overall theme taught me a lot about HTML,CSS & JavaScript!</p>
    
    <p className="app__profiles-p-text">As of today, I've had the privilege of working at a start-ups, a marketing agency and variety of clients. My main focus these days is learning advanced JavaScripts concepts to improve my knowlege.
    </p>
    <div className="app__profiles">
        {abouts.map((about, index) =>(
            <motion.div
                whileInView={{ opacity: 1 }}
                whileHover={{ scale:1.1 }}
                transition={{ duration: 0.5, type: 'tween'}}
                className="app__profile-item"
                key={about.title + index}
            >
              <img src={urlFor(about.imgUrl)} alt={about.title}  />
              <h2 className="bold-text" style={{ marginTop:20 }}>{about.title}</h2>
              <p className="p-text" style={{ marginTop:10 }}>{about.description}</p>
            </motion.div>

        ))}
    </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'), 
  'about',
  "app__whitebg"
  ); 