import React from 'react'
import { BsTwitter, BsInstagram, BsGithub, BsLinkedin } from 'react-icons/bs'
import { FaFacebook } from 'react-icons/fa'
import './socialMedia.scss'

function SocialMediaBar() {
  return (
    <div className="app__social_bar">
        <div>
            <a href='https://github.com/Febby' target="_blank" rel="noreferrer">
                <BsGithub/>
            </a>
        </div>
        <div>
            <a href='https://www.linkedin.com/in/febby-gunawan-5a596026/' target="_blank" rel="noreferrer">
                <BsLinkedin/>
            </a>
        </div>
        <div>
            <a href='https://twitter.com/Febby_Gunawan' target="_blank" rel="noreferrer">
                <BsTwitter/>
            </a>
        </div>
        <div>
            <a href='https://www.facebook.com/itsmefebbygunawan/' target="_blank" rel="noreferrer" >
                <FaFacebook/>
            </a>
        </div>
        <div>
            <a href='https://instagram.com/febbydood' target="_blank" rel="noreferrer" >
                <BsInstagram/>
            </a>
        </div>


    </div>
  )
}

export default SocialMediaBar