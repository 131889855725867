import React from 'react'

function NavigationDots({ active }) {
   
  return (
    <div className="app__navigation">
      {['home','about','work','skills','contact'].map((item, index) => (
        <a 
          className="app__navigation-dot"
          style={active === item ? {backgroundColor: '#313BAC'} : { }}
          href={`#${item}`}
          key={item + index}
        >
          <span style={{position: "absolute", left: "-9999px"}}>{item}</span>
        </a>
      ))}
    </div>
  )
}

export default NavigationDots