import React from 'react'

import { About,Footer,Header, Skills, Work } from './container'
import { NavBar,SocialMediaBar } from "./components";
import './app.scss'


const App = () => {
  return (
    <div className="app">
        <NavBar/>
        <SocialMediaBar/>
        <Header/>
        <About/>
        <Work/>
        <Skills/>
        <Footer/>
    </div>
  )
}

export default App